import React, { ReactNode } from 'react';

import { FullHeightLoader } from './Spinners';

interface Props {
    children: ReactNode
    fullPage?: boolean;
}

function Suspense({ children, fullPage = false }: Props) {
    return (
        <React.Suspense fallback={<FullHeightLoader fullPage={fullPage} />}>
            {children}
        </React.Suspense>
    )
}


export default Suspense