import React, {
    useCallback, useState, useContext, useEffect, useMemo, useRef, createContext
} from 'react';
import type { ReactNode } from "react";
import { useLocation, Navigate } from 'react-router-dom';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { API } from '../configs/api-configs';
import { arrayCharCase } from '../utils/helpers';
import { FullHeightLoader } from '../components/Spinners';
import { resetSessionTimer } from './session-controller';

dayjs.extend(duration)


interface AuthProviderType {
    children: ReactNode;
}

interface ProtectedRouteType {
    children: ReactNode;
    allowedTo?: string[];
    fullPageSuspense?: boolean;
}

interface UserType {
    loading: boolean;
    data: {
        first_name: string;
        last_name: string;
        phone_number: string;
        email: string;
        role: string;
    } | null;
}


interface AuthContextType {
    user: UserType;
    signIn: (token: any, callback?: VoidFunction) => void;
    signOut: (callback?: VoidFunction) => Promise<void>;
    getUser: () => Promise<void>;
}


let AuthContext = createContext<AuthContextType>(null!);

function AuthProvider({ children }: AuthProviderType) {
    const [user, setUser] = useState<UserType>({ loading: true, data: null });
    const events = useMemo(
        () => ['click', 'load', 'scroll', 'keydown', 'mousemove', 'mousedown', 'keydown', 'change'], []
    );
    const timeInterval = useRef<any>();

    const getUser = useCallback(async () => {
        const token = localStorage.getItem('token');
        if (token) {
            API.defaults.headers.common["Authorization"] = `Bearer ${token}`;

            await API.get('/users/profile')
                .then((response) => {
                    if (response.status === 200)
                        setUser({ loading: false, data: response.data.detail });
                    else
                        throw new Error("Failed to authenticate");
                }).catch(() => {
                    localStorage.removeItem('token');
                    setUser({ loading: false, data: null });
                });
        } else {
            setUser({ loading: false, data: null });
        }
    }, []);


    const signIn = useCallback(async (login: any, callback?: VoidFunction,) => {
        localStorage.setItem('token', login.token);

        await getUser();

        if (callback)
            setTimeout(() => callback(), 600);
    }, [getUser]);


    const signOut = useCallback(async (callback?: VoidFunction) => {
        await API.post('/logout').catch(() => { });

        if (localStorage.getItem('token'))
            localStorage.removeItem('token');

        setUser({ loading: false, data: null });

        if (timeInterval.current)
            clearTimeout(timeInterval.current);

        if (callback)
            setTimeout(() => callback(), 600);
    }, []);


    useEffect(() => {
        getUser();
    }, [getUser]);


    // Resets session timer on user login
    useEffect(() => {
        resetSessionTimer();
    }, [user]);


    // Resets session timer on the registered windows event trigger.
    // And unregister windows events on user logout.
    useEffect(() => {
        if (user.data) {
            events.forEach((event) => {
                window.addEventListener(event, resetSessionTimer);
            });
        } else {
            events.forEach((event) => {
                window.removeEventListener(event, resetSessionTimer);
            });
        }
    }, [events, user]);


    // Checks user interaction with the browser
    // and signout the expired session.
    useEffect(() => {
        if (user.data) {
            timeInterval.current = setInterval(() => {
                const last_timestamp = localStorage.getItem('session');
                if (last_timestamp) {
                    const date1 = dayjs(Date.now());
                    const date2 = dayjs(parseInt(last_timestamp));

                    const diff = dayjs.duration(date1.diff(date2)).asMinutes();

                    // If there is no user interaction in 30 minutes signout the current session
                    if (diff > 30)
                        signOut();
                }

            }, 1000);
        } else {
            clearTimeout(timeInterval.current);
        }
    }, [user, signOut]);



    const value: AuthContextType = {
        user: user,
        signIn: signIn,
        signOut: signOut,
        getUser: getUser
    };


    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}


const ProtectedRoute = ({ children, allowedTo, fullPageSuspense = false }: ProtectedRouteType) => {
    const { user } = useAuth();
    let location = useLocation();

    if (user.loading)
        return <FullHeightLoader fullPage={fullPageSuspense} />


    if (!user.data) {
        return <Navigate to="/" state={{ from: location }} replace />;
    }


    if (user.data && allowedTo && !arrayCharCase('uper', allowedTo)?.includes(user.data.role.toUpperCase())) {
        return <Navigate to="/error-403" state={{ from: location }} />;
    }


    return <>{children}</>;
}

function useAuth() {
    return useContext(AuthContext);
}

export { AuthContext, useAuth, ProtectedRoute };
export default AuthProvider;