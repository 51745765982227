import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import enUS from 'antd/locale/en_US';

import router from './routes/router';

// Importing styles
import "./assets/scss/style.scss";

const root = createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <ConfigProvider
        locale={enUS}
        theme={{
          token: {
            colorPrimary: '#CA4848',
            wireframe: false,
          },
        }}
      >
        <RouterProvider router={router} />
      </ConfigProvider>
  </React.StrictMode>
);
