/**
 * Gate a new value or the default
 *
 * @param value any
 * @param default_value any
 * @returns any
 */
export const get = (value: any, default_value: any = undefined) => {
  return value.lenth !== 0 ? value : default_value;
};

/**
 *Convert all elements of array to uper or lower case string
 *
 * @param type
 * @param array
 * @returns string[] | undefined
 */
export const arrayCharCase = (
  type: "uper" | "lower",
  array?: string[]
): string[] | undefined => {
  switch (type) {
    case "uper":
      return array?.map((element) => {
        return element.trim().toUpperCase();
      });

    case "lower":
      return array?.map((element) => {
        return element.trim().toUpperCase();
      });
  }
};

/**
 * Checks if object is empty
 *
 * @param value
 * @returns
 */
export function emptyObject(value: any) {
  return !value || Object.keys(value).length === 0;
}
