import axios from "axios";

import { get } from "../utils/helpers";
import {
  API_HTTP_PROTOCOL,
  API_SERVER_IP,
  API_SERVER_PORT,
  CDN_HTTP_PROTOCOL,
  CDN_SERVER_IP,
  CDN_SERVER_PORT,
} from './configs';

interface ApiConfigTypes {
  baseURL: string;
  timeout: number;
  headers?: object;
}

let API_BASE_URL: string = `${API_HTTP_PROTOCOL}://${API_SERVER_IP}`;
API_BASE_URL += get(API_SERVER_PORT) ? `:${API_SERVER_PORT}` : "";

let CDN_BASE_URL: string = `${CDN_HTTP_PROTOCOL}://${CDN_SERVER_IP}`;
CDN_BASE_URL += get(CDN_SERVER_PORT) ? `:${CDN_SERVER_PORT}` : "";

const PRODUCT_IMAGES_CDN_PATH = `${CDN_BASE_URL}/product_images`;

let configs: ApiConfigTypes = {
  baseURL: `${API_BASE_URL}`,
  timeout: 20000, // timeout 20 seconds
};

const API = axios.create(configs);
API.defaults.headers.common["Accept"] = "application/json";

const getToken = (): string | null => {
  return localStorage.getItem("token");
};

if (getToken())
  API.defaults.headers.common["Authorization"] = `Bearer ${getToken()}`;

export {
  API,
  API_HTTP_PROTOCOL,
  API_SERVER_IP,
  API_SERVER_PORT,
  API_BASE_URL,
  CDN_HTTP_PROTOCOL,
  CDN_SERVER_IP,
  CDN_SERVER_PORT,
  CDN_BASE_URL,
  PRODUCT_IMAGES_CDN_PATH,
  getToken,
};
