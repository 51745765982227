import React from 'react';
import { App as AntApp } from "antd";
import { Outlet } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';

import AuthProvider from './auth/auth-manager';
import store from './store/store';

function App() {
  return (
    <AntApp>
      <AuthProvider>
        <ReduxProvider store={store}>
          <Outlet />
        </ReduxProvider >
      </AuthProvider>
    </AntApp>
  );
}

export default App;
