import { Spin } from 'antd';


/**
 * The collection of reusable Loading spinners
 * @author Bantayehu Fikadu <bantayehuf@gmail.com>
 */


interface FullHeightLoaderType {
    size?: "small" | "large" | "default" | undefined;
    fullPage?: boolean;
}

interface SimpleLoaderType {
    size?: "small" | "large" | "default" | undefined;
}


/**
 * Fit the height and width of parent element and align spinner 
 * horzontally and vertically center
 * @param size
 * @returns <React.ReactNode>
 */
const FullHeightLoader = ({ size = 'large', fullPage = false }: FullHeightLoaderType) => {
    let style;
    if (fullPage)
        style = { height: '100vh' }
    else
        style = { height: '100%' }

    return (
        <div style={style} className="d-flex justify-content-center align-items-center">
            <Spin size={size} />
        </div>
    )
}


/**
 * Simple Loading Spinner align horzontally center of its parent
 * @param size 
 * @returns <React.ReactNode>
 */
const SimpleLoader = ({ size = 'default' }: SimpleLoaderType) => {
    return (
        <div className="d-flex justify-content-center py-2">
            <Spin size={size} />
        </div>
    );
}


export {
    FullHeightLoader,
    SimpleLoader,
}