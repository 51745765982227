import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface CounterState {
  value: number;
}

const initialState: CounterState = {
  value: 0,
};

const counterSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    increment: (state, action: PayloadAction<CounterState>) => {
      state.value += action.payload.value;
    },
  },
});

export const { increment } = counterSlice.actions;
export default counterSlice.reducer;
