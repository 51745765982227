import { API } from "../configs/api-configs";

/**
 * Fetch Name and ID of all product categories
 *
 * @returns []
 */
export const fetchProductCategories = async () => {
  return await API.get("/product-categories/get/")
    .then((response) => {
      return response.data.detail.results.map((item: any) => {
        return { value: item.id, label: item.name };
      });
    })
    .catch(() => []);
};
