import config from "./.configs-production.json";

const API_HTTP_PROTOCOL: string = config.API_HTTP_PROTOCOL;
const API_SERVER_IP: string = config.API_SERVER_IP;
const API_SERVER_PORT: string = config.API_SERVER_PORT;

const CDN_HTTP_PROTOCOL: string = config.CDN_HTTP_PROTOCOL;
const CDN_SERVER_IP: string = config.CDN_SERVER_IP;
const CDN_SERVER_PORT: string = config.CDN_SERVER_PORT;

const OTP_TOKEN: string = config.OTP_TOKEN;


export {
    API_HTTP_PROTOCOL,
    API_SERVER_IP,
    API_SERVER_PORT,
    CDN_HTTP_PROTOCOL,
    CDN_SERVER_IP,
    CDN_SERVER_PORT,
    OTP_TOKEN,
}