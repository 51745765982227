import React, { lazy } from "react";
import { Outlet, RouteObject } from "react-router-dom";

import App from "../App";
import Suspense from "../components/Suspense";
import RouteError from "../pages/error/RouteError";
import { ProtectedRoute } from "../auth/auth-manager";
import { fetchProductCategories } from "../services/product-categories-api";

const Login = lazy(() => import('../pages/login/Login'));
const ForgotPassword = lazy(() => import('../pages/login/ForgotPassword'));
const Logout = lazy(() => import('../pages/login/Logout'));
const Error403 = lazy(() => import('../pages/error/Error403'));

const Dashboard = lazy(() => import('../pages/Dashboard'));
const MainContainer = lazy(() => import('../containers/MainContainer'));
const AddProductCategory = lazy(() => import('../pages/product-category/AddProductCategory'));
const ManageProductCategories = lazy(() => import('../pages/product-category/ManageProductCategories'));
const AddProduct = lazy(() => import('../pages/product/AddProduct'));
const MyProducts = lazy(() => import('../pages/product/MyProducts'));
const ManageProducts = lazy(() => import('../pages/product/ManageProducts'));

const routes: RouteObject[] = [
    {
        path: '/',
        element: <App />,
        children: [
            {
                index: true,
                element: <Suspense fullPage={true}><Login /></Suspense>,
            }, {
                path: 'system',
                element: (
                    <ProtectedRoute fullPageSuspense={true}>
                        <Suspense fullPage={true}><MainContainer /></Suspense>
                    </ProtectedRoute>
                ),
                children: [
                    {
                        index: true,
                        element: <Suspense><Dashboard /></Suspense>
                    }, {
                        path: 'product-categories',
                        element: (
                            <ProtectedRoute allowedTo={['admin']}>
                                <Outlet />
                            </ProtectedRoute>
                        ),
                        children: [
                            {
                                index: true,
                                element: <Suspense><ManageProductCategories /></Suspense>,
                            }, {
                                path: 'add',
                                element: <Suspense fullPage={true}><AddProductCategory /></Suspense>

                            }
                        ]
                    }, {
                        path: 'products',
                        element: <Outlet />,
                        children: [
                            {
                                index: true,
                                element: (
                                    <ProtectedRoute allowedTo={['admin']}>
                                        <Suspense><ManageProducts /></Suspense>
                                    </ProtectedRoute>
                                ),
                            }, {
                                path: 'add',
                                loader: fetchProductCategories,
                                element: (
                                    <ProtectedRoute allowedTo={['seller']}>
                                        <Suspense fullPage={true}><AddProduct /></Suspense>
                                    </ProtectedRoute>
                                )
                            }, {
                                path: 'my-products',
                                loader: fetchProductCategories,
                                element: (
                                    <ProtectedRoute allowedTo={['seller']}>
                                        <Suspense fullPage={true}><MyProducts /></Suspense>
                                    </ProtectedRoute>
                                )
                            }
                        ]
                    }
                ]
            }, {
                path: '/forgot-password',
                element: <Suspense fullPage={true}><ForgotPassword /></Suspense>,
            }, {
                path: '/logout',
                element: <Suspense fullPage={true}><Logout /></Suspense>
            }, {
                path: '/error-403',
                element: <Suspense fullPage={true}><Error403 /></Suspense>
            }
        ],
        errorElement: <RouteError />
    }
];


export default routes;