import React from 'react';
import { useRouteError } from 'react-router-dom';
import { Button } from 'antd';

// importing styles
import "../../assets/scss/error-page-styles.scss";

function RouteError() {
    const error: any = useRouteError() as Error;
    return (
        <div className="error-container">
            <div className="error-container-inner">
                <div className="lock d-flex justify-content-center">
                    <span className="text-white align-self-center">
                        {error.status || JSON.stringify(error.status)}
                    </span>
                </div>
                <div className="mt-3">
                    <h1>Oops, something went wrong 😩 </h1>
                    <p>{error.message || JSON.stringify(error.data)}.</p>
                    <Button type='primary' className='mt-2 px-2' size='middle' onClick={() => (window.location.href = "/")}>
                        Click here to reload
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default RouteError